// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

import jQuery from "jquery";
import "jquery-ui-dist/jquery-ui";

import { Application } from "@hotwired/stimulus";
import { registerControllers } from "stimulus-vite-helpers";
import { Turbo } from "@hotwired/turbo-rails";
import "chartkick/chart.js";

Turbo.start();
const application = Application.start();
const controllers = import.meta.globEager("./controllers/**/*_controller.js");
window.Stimulus = application;
registerControllers(application, controllers);
$(window).on("turbo:load", function () {
  console.log(typeof $ === "function" ? "JQuery loaded" : "JQuery not loaded");
});
